import React, { lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const VPSkvm = lazy(() => import('sections/hero/VPSkvm.js'));
const ServicesKVM = lazy(() => import('sections/services/ServicesKVM.js'));
const BotonCloud = lazy(() => import('sections/services/BotonCloud.js'));
const HeroKVM = lazy(() => import('sections/hero/HeroKVM.js'));
const VPSsinaKVM = lazy(() => import('sections/services/VPSsinaKVM.js'));
const VPShdd = lazy(() => import('sections/hero/VPShdd.js'));
const VPSsinaHDD = lazy(() => import('sections/services/VPSsinaHDD.js'));
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));

class Index extends React.Component {

  render() {

    

    return (
      <div>
        <Helmet>
          <title>VPS KVM: Virtualización potente | Cali, Santa Rosa de Cabal</title>
          <meta name="description" content="Descubre la potencia de nuestros Servidores VPS KVM. Virtualización avanzada para un rendimiento excepcional. VPS en Cali y Santa Rosa de Cabal." />
        </Helmet>
        <Layout>
          <VPSkvm />          
          <VPSsinaKVM />
          <VPShdd />
          <VPSsinaHDD />
          <ServicesKVM />
          <HeroKVM />          
          <Llamenos />
          <BotonCloud />          
          <ContactCreative />
          <Footer />
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`